<template>
  <ion-grid fixed>
    <ion-row class=" ion-margin-top">
      <ion-col class="ion-align-self-center" size-md="6" size="12">
        <ion-card v-if="!isForgetPassword">
          <ion-card-content>
            <p color="primary">
              <b>
                <ion-text
                  v-text="NombreCompleto"
                  color="primary"
                  class="largeFontSize"
                ></ion-text>
              </b>
            </p>
            <p>
              <b v-text="user.email"></b>
            </p>
          </ion-card-content>
        </ion-card>
        <ion-card>
          <ion-card-header>
            <ion-card-title>Cambiar Contraseña</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <form @submit.prevent="submit">
              <ion-item v-if="!isAbm && !isForgetPassword">
                <ion-label position="floating">Contraseña Actual</ion-label>
                <ion-input
                  v-model.lazy="actualPassword"
                  :type="showActualPassword ? 'text' : 'password'"
                  placeholder="********"
                  required
                ></ion-input>
                <ion-button
                  fill="clear"
                  slot="end"
                  size="large"
                  @click="showActualPassword = !showActualPassword"
                >
                  <ion-icon
                    class="showPass ion-margin-top"
                    color="dark"
                    :icon="
                      showActualPassword
                        ? icons.eyeOffOutline
                        : icons.eyeOutline
                    "
                  ></ion-icon>
                </ion-button>
              </ion-item>
              <ion-item>
                <ion-label position="floating">Nueva Contraseña</ion-label>
                <ion-input
                  v-model.lazy="newPassword"
                  :type="showNewPassword ? 'text' : 'password'"
                  placeholder="********"
                  required
                ></ion-input>
                <ion-button
                  fill="clear"
                  slot="end"
                  size="large"
                  @click="showNewPassword = !showNewPassword"
                >
                  <ion-icon
                    class="showPass ion-margin-top"
                    color="dark"
                    :icon="
                      showNewPassword ? icons.eyeOffOutline : icons.eyeOutline
                    "
                  ></ion-icon>
                </ion-button>
              </ion-item>
              <ion-item>
                <ion-label position="floating">Repita Contraseña</ion-label>
                <ion-input
                  v-model.lazy="newPassword2"
                  :type="showNewPassword2 ? 'text' : 'password'"
                  placeholder="********"
                  required
                ></ion-input>
                <ion-button
                  fill="clear"
                  slot="end"
                  size="large"
                  @click="showNewPassword2 = !showNewPassword2"
                >
                  <ion-icon
                    class="showPass ion-margin-top"
                    color="dark"
                    :icon="
                      showNewPassword2 ? icons.eyeOffOutline : icons.eyeOutline
                    "
                  ></ion-icon>
                </ion-button>
              </ion-item>
              <ion-item v-show="error">
                <ion-text color="danger">
                  <h4>{{ error }}</h4>
                </ion-text>
              </ion-item>
              <ion-row class="ion-float-right">
                <ion-col>
                  <ion-button
                    fill="outline"
                    class="login-btn"
                    color="medium"
                    @click="cancel"
                  >
                    Cancelar
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button class="ion-float-right login-btn" type="submit">
                    Aceptar
                  </ion-button>
                </ion-col>
              </ion-row>
            </form>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import capitalizeWords from "../utils/capitalizeWords";
import {
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonCol,
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonText,
  IonInput,
  IonLabel,
  IonIcon
} from "@ionic/vue";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";

export default {
  props: {
    user: { type: Object, required: false, default: () => {} },
    loading: { type: Boolean, default: false },
    error: { type: String, default: "" },
    success: { type: Boolean, default: false },
    isAbm: { type: Boolean, default: false },
    isForgetPassword: { type: Boolean, default: false }
  },

  components: {
    IonGrid,
    IonText,
    IonCardContent,
    IonRow,
    IonCard,
    IonCardTitle,
    IonCol,
    IonButton,
    IonCardHeader,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon
  },

  data: () => ({
    reset: false,
    actualPassword: "",
    newPassword: "",
    newPassword2: "",
    showActualPassword: false,
    showNewPassword: false,
    showNewPassword2: false,
    icons: {
      eyeOutline,
      eyeOffOutline
    }
  }),

  computed: {
    NombreCompleto() {
      return capitalizeWords(this.user.apellido + " " + this.user.nombre);
    },
    listRoles() {
      return "Todavía no los hice";
    }
  },
  watch: {
    user: "cleaner",
    success(val) {
      if (val) {
        this.cleaner();
      }
    }
  },

  methods: {
    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    cleaner() {
      this.reset = !this.reset;
      this.newPassword = "";
      this.actualPassword = "";
      this.newPassword2 = "";
      this.showActualPassword = false;
      this.showNewPassword = false;
      this.showNewPassword2 = false;
    },

    submit() {
      this.$emit("update:success", false);
      if (
        this.newPassword &&
        this.newPassword2 &&
        this.actualPassword !== this.newPassword &&
        this.newPassword === this.newPassword2
      ) {
        this.$emit("submit", {
          actualPassword: this.actualPassword,
          newPassword: this.newPassword
        });
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.error = "La contraseña nueva no debe ser igual a la actual";
      }
    }
  }
};
</script>

<style scoped>
.largeFontSize {
  font-size: x-large;
}
</style>
