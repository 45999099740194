<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ChangePassword
          :user="user"
          v-model:success="success"
          :error="error"
          @submit="submit"
          @cancel="$router.push({ name: 'home' })"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, alertController } from "@ionic/vue";
import ChangePassword from "../../components/ChangePassword.vue";
import { mapActions, mapState } from "vuex";
//import { Usuarios } from "@/api";

export default {
  components: {
    IonContent,
    IonPage,
    ChangePassword
  },
  data: () => ({
    error: "",
    success: false
  }),

  computed: {
    ...mapState(["user"])
  },

  methods: {
    ...mapActions(["changePassword"]),

    async showDialog(error = null) {
      console.log(error);
      const alert = await alertController.create({
        header: error ? "Error" : `Contraseña Actualizada`,
        message: error
          ? "Error al actualizar la contraseña"
          : `La contraseña ha sido actualizada`,
        buttons: [
          {
            text: "Aceptar",
            handler: () => {
              if (!error) {
                this.$router.push({ name: "home" });
              }
            }
          }
        ]
      });
      return alert.present();
    },

    async submit({ actualPassword, newPassword }) {
      this.error = "";
      if (this.success) {
        return;
      }
      const { error, status } = await this.changePassword({
        actualPassword,
        newPassword
      });
      if (!error) {
        this.success = true;
        this.showDialog();
      } else {
        if (status && status === 500) {
          return;
        }
        this.showDialog(error);
      }
      //}
    }
  }
};
</script>

<style scoped>
.leftMargin2vh {
  margin-left: 2vh;
}

.margen8pxArriba {
  margin-top: 8px;
}
</style>
